<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import config from './config'

export default {
  name: 'Orders',
  setup() {
    const { MODULE_NAME, store, model } = config()

    // Register on enter section
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, model)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>
